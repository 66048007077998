
token-section {
  width: 100%;
  margin: 0 auto;
}

.token-images, .token-captions {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
}

.token-image-wrapper {
  text-align: center
}



.token-captions {
  margin-top: 5px;
  text-align: center;
}