.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#sidebar.active {
    margin-left: -250px;
}

select {
    font-size: .9rem;
    padding: 2px 5px;
}

/*#applex {
  min-width:300px;
}*/

.dropdown-menu {
  min-width:300px;
}

.btn-primary {
	background: #f9fafd;
	color: #5061ee;
	border: none;
}
.form-control-sm {
    border: thin #dee2e6 solid;
}
.summary_select{
	color: #5061ee;
}

.datepicker {
	width: 14em; 
	/*font-size:10px;*/
}
.modal-lg {
   		max-width: 80%;
}
.chart-dropdown-menu {
    width: 10px !important;
    /*height: 400px !important;*/
}

.avatar-text {
  font: 24px "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-anchor: middle;
  pointer-events: none;
}

.avatar-circle {
  fill: #ccc;
}

.avatar-node:hover circle {
  fill: orange;
}
.background {
  fill: none;
  pointer-events: all;
}
.close {
    float:right;
    display:inline-block;
    padding:2px 5px;
}
.close:hover {
    float:right;
    display:inline-block;
    padding:2px 5px;
}
.accordion-body {
	background-color:#fafbfe; 
	color:#000080;
}

.wrapper {
    /*display: flex;*/
    width: 100%;
    align-items: stretch;
}
#sidebar.active {
    margin-left: -250px;
}

.meritic_mpurple{
	color:#6c6699;
}
.meritic_dblue{
	color:#000080;
}
.plain-bg {
	background-color: '#fafbfe';
}

.consumer_bg{
	background-color:#eef2f6;
}
.consumer_usecase_row{
	margin-top:-1em; 
	margin-bottom:1em;
}

.banner_action{
	position: absolute; 
	bottom: 0px; 
	right:20px;
}
.dropdown-menu {
  min-width:300px;
}

.form-control-sm {
    border: thin #dee2e6 solid;
}
.summary_select{
	color: #5061ee;
}

.datepicker {
	width: 14em; 
	/*font-size:10px;*/
}

.chart-dropdown-menu {
    width: 10px !important;
    /*height: 400px !important;*/
}

.close {
    float:right;
    display:inline-block;
    padding:2px 5px;
}
.close:hover {
    float:right;
    display:inline-block;
    padding:2px 5px;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
