/*
* {box-sizing: border-box;}
ul {list-style-type: none;}
*/
/*body {font-family: Verdana, sans-serif;}*/


.tableWrap {
  height: 400px;
  overflow: auto;
}
thead {
  position: sticky;
  top: 0;
}
table {
 border-collapse: collapse;
}


tbody {
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: hidden;  /* Hide the horizontal scroll */
}


.month {
  padding: 5px 25px;
  width: 100%;
  background: #6666B2;
  text-align: center;
}

.month tr {
  margin: 0;
  padding: 0;
}

.month tr td {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.month .prev {
  float: left;	
  padding-top: 10px;
}

.month .next {
  float: right;
  padding-top: 10px;
}

.weekdays {
  margin: 0;
  padding: 0px 0;
  background-color: #ddd;
}

.weekdays td {
  display: inline-block;
  width: 12%;
  color: #666;
  text-align: center;
}
/*
.hihours {
  border-color: white !important;
  border-bottom: solid;
  list-style-type: none;
  display: inline-block;
  width: 12%;
  line-height:0%;
  text-align: center;
  color: #777;
}


.hiday {
  border-color: white !important;
  border-left: solid;
  list-style-type: none;
  display: inline-block;
  width: 12%;
  line-height:0%;
  text-align: center;
  color: #777;
}
*/

.days {
  padding: 0px 0;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height:10px;
  background: #eee;
  border-bottom: solid white thin;
}


.halfhour {
  padding: 0px 0;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height:10px;
  background: #eee;
  border-bottom: none;
  margin: 0;
}


.days td {


  display: inline-block;

  width: 12%;
  /*line-height:5%;*/
  text-align: center;
  margin-top: -1px;
  margin-bottom: 0px;
  font-size:10px;
  color: #777;
}

.halfhour td {


  display: inline-block;
  width: 12%;
  /*line-height:5%;*/
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size:10px;
  color: #777;
}


/*
.halfhour td {
  padding: 10px 0;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height:5%;
  background: #eee;
  border-bottom: none;
  margin: 0;
}*/


.active-anyone {
  width: 100%;
  height: 100%;
  /*margin-bottom: 1px;
  margin-top: -5px;*/
  border-right-width: 1px;
  border-left-width: 1px;
  border-left-color: white;
  border-right-color: white;
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  /*font-size: 8px;
  line-height:5%;*/
  color:#000080;
  background-color: #CCCCE5;
}
	
	
.active-groups {
  width: 100%;
  height: 100%;
  border-right-width: 1px;
  border-left-width: 1px;
  border-left-color: white;
  border-right-color: white;
  margin-bottom: 1px;
  margin-top: -5px;
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  /*font-size: 8px;
  line-height:5%;*/
  color: white;
  background-color: #000080;
}



/* Add media queries for smaller screens */
@media screen and (max-width:720px) {
  .weekdays li, .days li {width: 13.1%;}
}

@media screen and (max-width: 420px) {
  .weekdays td, .days td {width: 12.5%;}
  .days td .active {padding: 2px;}
}

@media screen and (max-width: 290px) {
  .weekdays td, .days td {width: 12.2%;}
}