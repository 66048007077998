.root {
  flex: 1 1 auto;
  max-height: 100%;

  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.root > *:not(.fill) {
  flex: 0 0 auto;
}

.fill {
  flex: 1 1 auto;
  overflow: hidden;
}

.Table {
  display: table;
  font-size: 1rem;
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

.Table .Row { display: table-row; }

.Table .BodyCell, .Table .HeaderCell {
  display: table-cell;
  line-height: 2em;
  padding: 0 0.75em;
  border: 1px rgba(255, 255, 255, 0.75) solid;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 2rem;
}

.Table .HeaderCell {
  max-height: 3.5em;
  padding: 0.75em 0.75em;
  border-bottom-width: 2px;
  position: relative;
}

.ResizeHandle {
  user-select: none;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  opacity: 0.8;
}

.ResizeHandleActive {
  opacity: 1;
}

.ViewportDetails {
  flex: 0 1 auto;
  font-size: 1rem;
  text-align: right;
  border-top: 1px white solid;
  padding-top: 1em;
}