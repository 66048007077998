
.collection_hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}
.cf:before, .cf:after{
    content:"";
    display:table;
}

.cf:after{
    clear:both;
}

.cf{
    zoom:1;
}    
/* Form wrapper styling */

.condition-select-wrapper {
    width: 700px;
    margin: 50px auto 50px auto;
    border-radius: 10px;
}


.search-wrapper {
    width: 450px;
    margin: 50px auto 50px auto;
    border-radius: 40px;
  background: transparent;
    box-shadow: 0 4px 20px -2px #e9e9e9;
}

/* Form text input */

.search-wrapper input {
  padding-left: 20px;
    width: 330px;
    height: 20px;
    padding: 10px 5px;
    float: left;   
    border: 0;
    background: #fff;
    border-radius: 40px;
    border-top-style: none;
}

.search-wrapper input:focus {
    outline: 0;
    background: #fff;
    box-shadow: 0 0 2px rgba(0,0,0,0.8) inset;
}

.search-wrapper input::-webkit-input-placeholder {
   color: #999;
   font-weight: normal;
   font-style: italic;
  padding-left: 20px;
}

.search-wrapper input:-moz-placeholder {
    
    color: #999;
    font-weight: normal;
    font-style: italic;
}

.search-wrapper input:-ms-input-placeholder {
    color: #999;
    font-weight: normal;
    font-style: italic;
  border-style: none;
}   

/* Form submit button */
.search-wrapper button {
    overflow: visible;
    position: relative;
    float: right;
    border: 0;
    padding: 0;
    cursor: pointer;
    height: 40px;
    width: 110px;
    font: 13px/40px 'lucida sans', 'trebuchet MS', 'Tahoma';
    color: #fff;
    background: #000080;
    border-radius: 40px;
    text-shadow: 0 -1px 0 rgba(0, 0 ,0, .3);
}  

.search-wrapper button:hover{    
/*     background: #e54040; */
}  

.search-wrapper button:active,
.search-wrapper button:focus{  
    background: #000080;
    outline: 0;  
}

.search-wrapper button:focus:before,
.search-wrapper button:active:before{
        border-right-color: #c42f2f;
}     

.search-wrapper button::-moz-focus-inner { /* remove extra button spacing for Mozilla Firefox */
    border: 0;
    padding: 0;
}   

.service-sector-active {
	background-color: #cccce5 !important;
}
.time-unit-active {
	background-color: #6666B2 !important;
	color: #ffffff !important;
}

.ele-with-border-radius {
    border-radius: 15px;
    overflow: hidden;
    perspective: 1px;
}