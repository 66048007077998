.ColumnSelector {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.Label {
  text-align: left;
}

.Checkbox {
  text-align: left;
}